<ng-container *transloco="let t">
  @if (!loading) {
    @if (orderDetails) {
      <div class="order-date">
        <pxw-icon icon="clock" size="xs"></pxw-icon>
        {{ formattedDate }}
      </div>
      <!-- PRE_CUSTOMER template -->
      <ng-container *ngTemplateOutlet="preCustomerTemplate"></ng-container>
      <!-- CUSTOMER -->
      @if (displayCustomerInfo) {
        <div class="order-section">
          <div class="order-section-title">{{ t('orderDetails.customerData') }}</div>
          <div class="order-section-body">
            <div class="customer">
              <div>
                <div class="customer-name">{{ orderDetails.personalInformation.name }}</div>
                <a href="tel:{{ orderDetails.personalInformation.phone }}" class="customer-phone">{{
                  orderDetails.personalInformation.phone
                }}</a>
              </div>
              <a
                class="customer-whatsapp"
                [href]="whatsappLink"
                [target]="whatsappTarget"
                (click)="onWhatsappClick()"
              >
                <pxw-icon icon="whatsapp"></pxw-icon>
              </a>
            </div>
          </div>
        </div>
      }
      <div class="order-section">
        <div class="order-section-title">{{ t('orderDetails.orderData') }}</div>
        <div class="order-section-body">
          <!-- PAYMENT -->
          <div class="order-subsection">
            <div class="order-subsection-icon">
              <pxw-icon icon="payment" size="md"></pxw-icon>
            </div>
            <div class="order-subsection-body">
              @if (orderDetails.paymentMethod) {
                <div class="order-subsection-item">
                  <div class="order-subsection-item-title">
                    {{ t('orderDetails.paymentMethod') }}
                  </div>
                  <div class="order-subsection-item-value">
                    {{ orderDetails.paymentMethod.name }}
                  </div>
                </div>

                @if (orderDetails.paymentMethod.type === 'CASH' && orderDetails.howMuch) {
                  <div class="order-subsection-item">
                    <div class="order-subsection-item-title">
                      {{ t('orderDetails.paymentHowMuch') }}
                    </div>
                    <div class="order-subsection-item-value">
                      {{ orderDetails.howMuch | currencyFormat }}
                    </div>
                  </div>
                }
              }
              @if (orderDetails.paymentLink) {
                @if (paymentUrl) {
                  <div class="order-subsection-item">
                    <div class="order-subsection-item-title">
                      {{ t('orderDetails.paymentLink') }}
                    </div>
                    <div class="order-subsection-item-value">
                      <a [href]="paymentUrl" target="blank">{{
                        t('orderDetails.openPaymentLink')
                      }}</a>
                    </div>
                  </div>
                }
                @if (orderDetails.paymentLink) {
                  @if (orderDetails.paymentLink.status) {
                    <div class="order-subsection-item">
                      <div class="order-subsection-item-title">{{ t('paymentStatus.title') }}</div>
                      <div class="order-subsection-item-value">
                        <!-- t(paymentStatus.PAID,paymentStatus.PENDING,paymentStatus.CANCELLED,paymentStatus.REFUNDED,paymentStatus.REJECTED)-->
                        {{ t('paymentStatus.' + orderDetails.paymentLink.status) }}
                      </div>
                    </div>
                  }
                  @if (orderDetails.paymentLink.updated) {
                    <div class="order-subsection-item">
                      <div class="order-subsection-item-title">
                        {{ t('paymentStatus.lastUpdated') }}
                      </div>
                      <div class="order-subsection-item-value">
                        {{ orderDetails.paymentLink.updated | date: 'dd/MM/yy - HH:mm' }}hs
                      </div>
                    </div>
                  }
                }
              }
              <div class="order-subsection-item">
                <div class="order-subsection-item-title">
                  {{ t('orderDetails.total') }}
                  @if (displayTotalDetails) {
                    <span class="show-total-details" (click)="showTotalDetails()">
                      {{ t('orderDetails.viewTotalDetails') }}
                    </span>
                  }
                </div>
                <div class="order-subsection-item-value">
                  {{ orderDetails.finalAmount | currencyFormat }}
                </div>
              </div>
            </div>
          </div>
          <!-- SHIPPING -->
          @if (orderDetails.shippingOption) {
            <div class="order-subsection">
              <div class="order-subsection-icon">
                <pxw-icon icon="shipping" size="md"></pxw-icon>
              </div>
              <div class="order-subsection-body">
                <div class="order-subsection-item">
                  <div class="order-subsection-item-title">
                    {{ t('orderDetails.shippingOption') }}
                  </div>
                  <div class="order-subsection-item-value">
                    {{ orderDetails.shippingOption.name }}
                  </div>
                </div>
                @if (isDelivery) {
                  <div class="order-subsection-item">
                    <div class="order-subsection-item-title">{{ t('orderDetails.address') }}</div>
                    <div class="order-subsection-item-value">
                      @if (addressLink) {
                        <a class="order-address-link" [href]="addressLink" target="_blank">
                          {{ address }}
                        </a>
                      }
                      @if (!addressLink) {
                        <span>{{ address }}</span>
                      }
                    </div>
                  </div>
                  @if (orderDetails.personalInformation.address?.floorAndNumber) {
                    <div class="order-subsection-item">
                      <div class="order-subsection-item-title">
                        {{ t('orderDetails.floorApartment') }}
                      </div>
                      <div class="order-subsection-item-value">
                        {{ orderDetails.personalInformation.address.floorAndNumber }}
                      </div>
                    </div>
                  }
                  @if (orderDetails.personalInformation.address?.references) {
                    <div class="order-subsection-item">
                      <div class="order-subsection-item-title">
                        {{ t('orderDetails.references') }}
                      </div>
                      <div class="order-subsection-item-value">
                        {{ orderDetails.personalInformation.address?.references }}
                      </div>
                    </div>
                  }
                  @if (orderDetails.shippingProvider && displayCustomerInfo) {
                    @if (!orderDetails.shippingProvider?.status) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{
                            orderDetails.shippingProvider.shippingId
                              ? 'Confirmar envío'
                              : 'Solicitar envío'
                          }}
                        </div>
                        <div class="order-subsection-item-value">
                          <pxw-button
                            (click)="
                              orderDetails.shippingProvider.shippingId
                                ? onConfirmShipping()
                                : onCreateShipping()
                            "
                            [loading]="
                              orderDetails.shippingProvider.shippingId
                                ? isShippingConfirm()
                                : isShippingCreate()
                            "
                            color="yellow"
                            display="inline"
                            size="sm"
                            [rounded]="true"
                          >
                            <pxw-icon icon="shipping" />
                            {{
                              orderDetails.shippingProvider.shippingId
                                ? 'Confirmar envío'
                                : 'Solicitar envío'
                            }}
                          </pxw-button>
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.shippingId) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.confirmationCode') }}
                        </div>
                        <div class="order-subsection-item-value">
                          {{ orderDetails.shippingProvider.shippingId }}
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.providerName) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.providerName') }}
                        </div>
                        <div class="order-subsection-item-value">
                          <img
                            [src]="
                              '/assets/images/shipping-providers/' +
                              orderDetails.shippingProvider.providerName +
                              '.webp'
                            "
                            alt="{{ orderDetails.shippingProvider.providerName }}"
                            class="provider-logo"
                          />
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.status) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.shippingStatus') }}
                        </div>
                        <div class="order-subsection-item-value">
                          <pxw-order-shipping-status
                            [status]="orderDetails.shippingProvider.status!"
                          />
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.estimatedDeliveryTime) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.estimatedDeliveryTime') }}
                        </div>
                        <div class="order-subsection-item-value">
                          {{
                            orderDetails.shippingProvider.estimatedDeliveryTime
                              | date: 'dd/MM/yyyy - HH:mm'
                          }}hs
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.trackingUrl) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.trackingUrl') }}
                        </div>
                        <div class="order-subsection-item-value">
                          <a
                            [href]="orderDetails.shippingProvider.trackingUrl"
                            class="order-tracking-url"
                            target="_blank"
                          >
                            {{ t('orderDetails.viewTracking') }}
                          </a>
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.supportUrl) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.supportUrl') }}
                        </div>
                        <div class="order-subsection-item-value">
                          <a
                            [href]="orderDetails.shippingProvider.supportUrl"
                            target="_blank"
                            class="order-support-url"
                          >
                            {{ t('orderDetails.contactSupport') }}
                          </a>
                        </div>
                      </div>
                    }

                    @if (orderDetails.shippingProvider?.additionalInfo) {
                      <div class="order-subsection-item">
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.additionalInfo') }}
                        </div>
                        <div class="order-subsection-item-value">
                          {{ orderDetails.shippingProvider.additionalInfo }}
                        </div>
                      </div>
                    }

                    @if (
                      orderDetails.shippingProvider?.status &&
                      orderDetails.shippingProvider?.status !== 'CANCELLED'
                    ) {
                      <div
                        class="order-subsection-item"
                        [ngClass]="{
                          'order-shipping-cancel-alert': !canCancelShipping
                        }"
                      >
                        <div class="order-subsection-item-title">
                          {{ t('orderDetails.cancelShippingText') }}
                        </div>
                        @if (canCancelShipping) {
                          <pxw-button
                            (click)="cancelShipping()"
                            [loading]="isShippingCanceled()"
                            color="yellow"
                            display="inline"
                            size="sm"
                            [rounded]="true"
                          >
                            {{ t('orderDetails.cancelShipping') }}
                          </pxw-button>
                        } @else {
                          <pxw-alert type="danger" display="inline-block" size="sm">
                            {{ t('orderDetails.cannotCancel') }}
                          </pxw-alert>
                        }
                      </div>
                    }
                  }
                }
                @if (orderDetails.tableNumber) {
                  <div class="order-subsection-item">
                    <div class="order-subsection-item-title">{{ t('orderDetails.table') }}</div>
                    <div class="order-subsection-item-value">{{ orderDetails.tableNumber }}</div>
                  </div>
                }
              </div>
            </div>
          }
          <!-- COUPON -->
          @if (orderDetails.couponConfigurationSnapshot) {
            <div class="order-subsection">
              <div class="order-subsection-icon">
                <pxw-icon icon="coupons" size="md"></pxw-icon>
              </div>
              <div class="order-subsection-body">
                <div class="order-subsection-item">
                  <div class="order-subsection-item-title">
                    {{ t('orderDetails.couponDiscount') }}
                  </div>
                  <div class="order-subsection-item-value">
                    {{ orderDetails.couponConfigurationSnapshot.code }}
                  </div>
                </div>
              </div>
            </div>
          }
          <!-- CUSTOM FIELDS -->
          @if (customFields) {
            <div class="order-subsection">
              <div class="order-subsection-icon">
                <pxw-icon icon="form" size="md"></pxw-icon>
              </div>
              <div class="order-subsection-body">
                @for (customField of customFields; track customField) {
                  <div class="order-subsection-item">
                    <div class="order-subsection-item-title">{{ customField.name }}</div>
                    <div class="order-subsection-item-value">{{ customField.value }}</div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <!-- PRODUCTS -->
      <div class="order-section">
        <div class="order-section-title">{{ t('orderDetails.products') }}</div>
        <div class="order-section-body">
          <pxw-cart-items-list
            [cartItems]="orderDetails.cartItems"
            [undefinedGroupName]="t('product.undefinedGroupName')"
          >
            <ng-template #itemTemplate let-cartItem>
              <app-resume-item
                [cartItem]="cartItem"
                [small]="true"
                [displaySku]="displaySku"
              ></app-resume-item>
            </ng-template>
          </pxw-cart-items-list>
        </div>
      </div>
      <pxw-modal-dialog
        #totalDetailsDialog
        icon="info"
        [title]="t('totalDetails.title')"
        type="modal"
        [showCloseButton]="true"
      >
        <app-order-total-details [orderDetails]="orderDetails"></app-order-total-details>
      </pxw-modal-dialog>
    } @else {
      <div class="empty-state">
        <pxw-alert type="warning">{{ t('orderDetails.emptyState') }}</pxw-alert>
      </div>
    }
  } @else {
    <div class="ui-loader">
      <pxw-icon icon="loading" [spin]="true"></pxw-icon>
    </div>
  }
</ng-container>
